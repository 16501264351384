.row {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);

  @include phone {
    grid-template-columns: repeat(1, 1fr);
  }

  @include phablet {
    grid-template-columns: repeat(1, 1fr);
  }
}

#see-more {
  text-transform: uppercase;
  text-align: center;
  margin: 50px;
  font: {
    size: 0.8em;
    family: $monospace;
  }
}
