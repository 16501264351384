@font-face {
  font-family: 'SF Pro Light';
  src: local('SF Pro Light'),
    url('/fonts/SF-Pro/SFProText-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url('/fonts/SF-Pro/SFProText-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'SF Pro Display Light';
  src: local('SF Pro'), url('/fonts/SF-Pro/SFProDisplay-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro'), url('/fonts/SF-Pro/SFProDisplay-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'SF Mono';
  src: local('SF Mono'), url('/fonts/SF-Mono/SFMono-Regular.ttf') format('truetype');
}

@import './styles/main';
@import './styles/hero';
@import './styles/section';
@import './styles/experience';
@import './styles/role';
@import './styles/projects';
@import './styles/card';
@import './styles/footer';